import { ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

const colorSchemas = {
  orange: {
    color: `${colors.brand.primary700}`,
    backgroundColor: `${colors.brand.primary50}`,
  },
  blue: {
    color: `${colors.brand.blue800}`,
    backgroundColor: `${colors.brand.blue25}`,
  },
};

export const ToolTipStyles: ComponentStyleConfig = {
  baseStyle: () => ({
    border: `${colors.brand.bluePrimary50}`,
    marginBottom: `${custom.space.md}`,
    background: `${colors.brand.blue25}`,
    color: `${colors.brand.gray900}`,
    padding: `${custom.space.lg}`,
    borderRadius: '10px',
    '& .chakra-tooltip__arrow': {
      borderRadius: '2px',
    },
  }),
  sizes: {
    sm: { width: '46px', height: '46px' },
    smd: { width: '180px', height: 'auto', fontSize: `${custom.font.xs}` },
    md: () => ({
      maxWidth: '240px',
      fontSize: `${custom.font.xs}`,
      height: '96px',
    }),
    lg: () => ({
      maxWidth: '380px',
      fontSize: `${custom.font.xs}`,
    }),
  },
  variants: {
    primary: () => ({
      position: 'relative',
      left: '24px',
      _after: {
        content: '""',
        position: 'absolute',
        right: '26px',
        bottom: '-17px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.blue25}`,
        transform: 'rotate(270deg)',
      },
    }),
    secondary: () => ({
      height: 'auto',
      width: 'auto',
      maxWidth: '250px',
      borderRadius: '8px',
      boxShadow: 'none',
      arrow: {
        backgroundColor: `${colors.brand.gray50}`,
      },
    }),
    centered: () => ({
      position: 'relative',
      left: '24px',
      color: `${colors.brand.gray900}`,
      fontSize: `${custom.font.sm}`,
      _after: {
        content: '""',
        position: 'absolute',
        right: '176px',
        bottom: '-17px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.blue25}`,
        transform: 'rotate(270deg)',
      },
    }),
    schoolData: () => ({
      position: 'relative',
      right: '100px',
      top: '30px',
      color: `${colors.brand.gray900}`,
      fontSize: `${custom.font.sm}`,
      _after: {
        content: '""',
        position: 'absolute',
        right: '176px',
        bottom: '-17px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.blue25}`,
        transform: 'rotate(270deg)',
      },
    }),
    registration: () => ({
      position: 'relative',
      left: '-6px',
      _after: {
        content: '""',
        position: 'absolute',
        top: '-16px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.blue25}`,
        transform: 'rotate(90deg)',
      },
    }),
    topicsCard: () => ({
      position: 'relative',
      top: '12px',
      left: '45px',
      backgroundColor: `${colors.brand.yellow400}`,
      color: `${colors.brand.gray900}`,
      boxShadow: 'none',

      _after: {
        content: '""',
        position: 'absolute',
        left: '20px',
        top: '-17px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.yellow400}`,
        transform: 'rotate(90deg)',
      },
    }),
    questionsTip: () => ({
      position: 'relative',
      bottom: '6px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '113px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    smallColored: ({ colorScheme }) => {
      return {
        position: 'relative',
        bottom: '0px',
        left: '0',
        backgroundColor: colorScheme
          ? colorSchemas[colorScheme as keyof typeof colorSchemas]
              ?.backgroundColor
          : `${colors.brand.primary50}`,
        color: colorScheme
          ? colorSchemas[colorScheme as keyof typeof colorSchemas]?.color
          : `${colors.brand.primary700}`,
        boxShadow: 'none',
        textAlign: 'center',
        fontWeight: 800,
        padding: '8px',

        _after: {
          content: '""',
          left: '0',
          right: '0',
          position: 'absolute',
          top: '48px',
          borderTop: `12px solid transparent`,
          borderBottom: `12px solid transparent`,
          borderRight: `12px solid ${
            colorScheme
              ? colorSchemas[colorScheme as keyof typeof colorSchemas]
                  ?.backgroundColor
              : colors.brand.primary50
          }`,
          transform: 'rotate(270deg)',
        },
      };
    },

    questionsTipBig: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '175px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '121px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    printTip: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '83px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    printSmallTip: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '216px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '60px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    skillTip: () => ({
      position: 'relative',
      bottom: '0px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '216px',
      height: '48px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '140px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    flashcardTip: () => ({
      position: 'relative',
      bottom: '-2px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '92px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    studentAssessmentTip: () => ({
      position: 'relative',
      bottom: '-2px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '50px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    flashcardTipSmall: () => ({
      position: 'relative',
      bottom: '-2px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '65px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    flashcardTipGame1: () => ({
      position: 'relative',
      bottom: '7px',
      left: '-10px',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '69px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    flashcardTipGame2: () => ({
      position: 'relative',
      bottom: '7px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px 16px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '48px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    flashcardTipGame3: () => ({
      position: 'relative',
      bottom: '7px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '67px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    printTipBig: () => ({
      position: 'relative',
      bottom: '6px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '175px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '119px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    fullScreenTip: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '60px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    modeTip: (props: any) => {
      return {
        position: 'relative',
        bottom: '6px',
        left: '0',
        backgroundColor: `${colors.brand.gray900}`,
        color: `${colors.brand.white}`,
        boxShadow: 'none',
        maxWidth: '276px',
        textAlign: 'center',
        padding: '8px',

        _after: {
          content: '""',
          left: '0',
          right: '0',
          position: 'absolute',
          top: `${props.arrowPadding}px`,
          borderTop: `12px solid transparent`,
          borderBottom: `12px solid transparent`,
          borderRight: `12px solid ${colors.brand.gray900}`,
          transform: 'rotate(270deg)',
        },
      };
    },
    reviewBlackTooltip: () => ({
      position: 'relative',
      bottom: '0px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '140px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '85px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    latestAssignmentsTooltip: () => ({
      position: 'relative',
      bottom: '-10px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '254px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '-35px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(90deg)',
        display: 'none',
      },
    }),
    latestAssignmentsButtonTooltip: () => ({
      position: 'relative',
      bottom: '-10px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '254px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '-85px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(90deg)',
      },
    }),
    latestAssignmentsReviewTooltip: () => ({
      position: 'relative',
      bottom: '-10px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '254px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '-80px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(90deg)',
      },
    }),
    latestAssignmentsUpDownTooltip: () => ({
      position: 'relative',
      bottom: '-2px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '105px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    placticeFlashcardTooltip: () => ({
      position: 'relative',
      bottom: '-10px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '254px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '-130px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(90deg)',
      },
    }),
    black: () => ({
      position: 'relative',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      textAlign: 'center',
      height: 'fit-content',
      minWidth: '150px',
      padding: '8px',
      '& .chakra-tooltip__arrow': {
        background: `${colors.brand.gray900} !important`,
      },
    }),
    smbTooltipBig: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '201px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '132px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    dashboardTooltipBig: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '238px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '165px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    dashboardTooltipTall: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '238px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '195px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    dashboardTooltipTallest: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '238px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '240px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    dashboardTooltipShort: () => ({
      position: 'relative',
      bottom: '4px',
      left: '0',
      backgroundColor: `${colors.brand.gray900}`,
      color: `${colors.brand.white}`,
      boxShadow: 'none',
      maxWidth: '238px',
      textAlign: 'center',
      padding: '8px',

      _after: {
        content: '""',
        left: '0',
        right: '0',
        position: 'absolute',
        top: '180px',
        borderTop: `12px solid transparent`,
        borderBottom: `12px solid transparent`,
        borderRight: `12px solid ${colors.brand.gray900}`,
        transform: 'rotate(270deg)',
      },
    }),
    basic: (props) => {
      return {
        background: `${colors.brand.gray900}`,
        color: `${colors.brand.white}`,
        boxShadow: 'none',
        textAlign: 'center',
        maxWidth: '238px',
        padding: '8px',
        whiteSpace: 'pre-line',
        height: 'fit-content',
        '--popper-arrow-bg': `${colors.brand.gray900}`,
        ...(props.hasArrow
          ? {
              margin: '4px 0px',
            }
          : {}),
      };
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
