import React, { createContext, useContext, useMemo, useState } from 'react';

type ChatbotNavigationContextType = {
  showAllElements: boolean;
  setShowAllElements: (showAllElements: boolean) => void;
};

const ChatbotNavigationContext = createContext<ChatbotNavigationContextType>({
  showAllElements: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowAllElements: () => {},
});

type ChatbotNavigationProviderProps = {
  children: React.ReactNode;
};

const ChatbotNavigationProvider = ({
  children,
}: ChatbotNavigationProviderProps) => {
  const [showAllElements, setShowAllElements] = useState(false);

  const contextValue = useMemo(
    () => ({
      showAllElements,
      setShowAllElements,
    }),
    [showAllElements]
  );

  return (
    <ChatbotNavigationContext.Provider value={contextValue}>
      {children}
    </ChatbotNavigationContext.Provider>
  );
};

export const useChatbotNavigation = () => useContext(ChatbotNavigationContext);

export { ChatbotNavigationProvider };
