import { createStandaloneToast } from '@chakra-ui/toast';

const { toast } = createStandaloneToast();

export const showToast = (
  title: string,
  description: string,
  status: 'error' | 'info' | 'success' | 'warning',
  duration?: number
) => {
  toast({
    position: 'top',
    title,
    description,
    status,
    duration: duration ?? 6000,
    isClosable: true,
  });
};

interface EnvVars {
  APP_URL: string | undefined;
  API_URL: string | undefined;
  STRIPE_KEY: string | undefined;
  ICONS_URL: string | undefined;
  IMAGES_URL: string | undefined;
  MEDIA_URL: string | undefined;
  NODE_ENV: string | undefined;
  LOG_LEVEL: string | undefined;
  RESOURCES_URL: string | undefined;
  SENTRY_DSN: string | undefined;
  GENERATED_IMAGES_URL: string | undefined;
}

const sanitize = (str: string | undefined) => {
  if (str && str.endsWith('/')) {
    return str.replace(/\/$/, '');
  }
  return str;
};

export const getEnvVars = (): EnvVars => {
  const envVars: EnvVars = {
    NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
    STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
    APP_URL: sanitize(process.env.NEXT_PUBLIC_APP_URL),
    API_URL: sanitize(process.env.NEXT_PUBLIC_API_URL),
    ICONS_URL: sanitize(process.env.NEXT_PUBLIC_ICONS_URL),
    MEDIA_URL: sanitize(process.env.NEXT_PUBLIC_MEDIA_URL),
    IMAGES_URL: sanitize(process.env.NEXT_PUBLIC_IMAGES_URL),
    GENERATED_IMAGES_URL: sanitize(
      process.env.NEXT_PUBLIC_GENERATED_IMAGES_URL
    ),
    RESOURCES_URL: sanitize(process.env.NEXT_PUBLIC_RESOURCES_URL),
    SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  };
  return envVars;
};
