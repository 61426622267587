/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { DakotaChatStep } from '@/components/common/DakotaChat/types';

type DakotaChatProviderProps = {
  children: React.ReactNode;
};

type DakotaChatContextType = {
  setSteps: React.Dispatch<React.SetStateAction<DakotaChatStep[]>>;
  steps: DakotaChatStep[];
  addStep: (
    payload: DakotaChatStep,
    cb?: (steps: DakotaChatStep[]) => void
  ) => void;
  updateStep: (payload: DakotaChatStep) => void;
  removeStep: () => void;
  resetSteps: () => void;
  checkIsActiveStep: (index: number) => boolean;
};

export const DakotaChatContext = createContext<DakotaChatContextType>({
  steps: [],
  setSteps: () => {},
  addStep: () => {},
  updateStep: () => {},
  removeStep: () => {},
  resetSteps: () => {},
  checkIsActiveStep: () => false,
});

const DakotaChatProvider = ({ children }: DakotaChatProviderProps) => {
  const [steps, setSteps] = useState<DakotaChatStep[]>([]);

  const addStep = useCallback(
    (data: DakotaChatStep, cb?: (d: DakotaChatStep[]) => void) => {
      if (cb) {
        cb(steps);
      }

      setSteps((prev) => [
        ...prev,
        { ...(data || {}), index: steps?.length || 0 },
      ]);
    },
    [setSteps, steps]
  );

  const removeStep = useCallback(() => {
    setSteps((prev) => {
      const newState = cloneDeep(prev);
      newState.pop();
      return newState;
    });
  }, [setSteps]);

  const updateStep = useCallback(
    (newData: DakotaChatStep) => {
      setSteps((prev) => {
        const newState = cloneDeep(prev);
        const oldData = newState[(newState?.length || 0) - 1];
        newState[(newState?.length || 0) - 1] = { ...oldData, ...newData };
        return newState;
      });
    },
    [setSteps]
  );

  const resetSteps = useCallback(() => {
    setSteps([]);
  }, [setSteps]);

  const checkIsActiveStep = useCallback(
    (index: number) => {
      const lastIndexStep = steps?.[(steps?.length || 0) - 1]?.index;
      return lastIndexStep === index;
    },
    [steps]
  );

  const contextValue = useMemo(
    () => ({
      steps,
      setSteps,
      addStep,
      updateStep,
      removeStep,
      resetSteps,
      checkIsActiveStep,
    }),
    [steps]
  );

  return (
    <DakotaChatContext.Provider value={contextValue}>
      {children}
    </DakotaChatContext.Provider>
  );
};

export const useDakotaChatContext = () => useContext(DakotaChatContext);

export { DakotaChatProvider };
