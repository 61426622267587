import { find, isEmpty } from 'lodash';
import { IconTypes } from '@/components/common/Icon/types';
import { requestWithAccessToken } from '@/modules/apiRequests/userRequest';
import { TransformedStudentType } from './types';

export const mutuallyExcludeStudents = (
  studentsSet1: Array<TransformedStudentType>,
  studentsSet2: Array<TransformedStudentType>
) => {
  return studentsSet1.filter((student) =>
    isEmpty(find(studentsSet2, { value: student.value ?? '' }))
  );
};

export const getCode = async (learnerId: string) => {
  const response = await requestWithAccessToken(
    `users/${learnerId}/regenerate-code`,
    false,
    'GET'
  );
  return response;
};

export const iconsMapper: { [key: string]: IconTypes } = {
  CAT4: 'CAT4',
  CEMSEL: 'CEM',
  GL11PLUS: 'GL11',
  SAT: 'SATs',
  ISEB: 'ISEB',
  LON11PLUS: 'Consortium11',
  TIFFIN: 'TIFFIN',
  SET: 'SET',
  FSCE11PLUS: 'FSCE11PLUS',
  CSSE: 'CSSE',
  ATOMTEST: 'ATOMTEST',
  ATOMTESTV2: 'ATOMTESTV2',
  '11PLUS': 'Consortium11',
  ETONY6: 'ETONY6',
  SHRTY: 'shrtyIcon',
};
