import { InputProps } from '@chakra-ui/react';

import { colors } from '@/styles/colors';
import { custom } from '@/styles/custom';

const focus = {
  _focus: {
    outline: 'none',
  },
};

export const InputStyles = {
  baseStyle: {
    field: {
      fontWeight: 500,
      width: '100%',
      _autofill: {
        boxShadow: '0 0 0px 1000px white inset',
        transition: 'background-color 5000s ease-in-out 0s',
        outline: 'none',
      },
      _disabled: {
        backgroundColor: 'transparent',
      },
    },
  },
  sizes: {
    sm: () => ({
      field: {
        width: '144px',
        height: '48px',
        fontSize: `${custom.font.md}`,
      },
    }),
    md: () => ({
      field: {
        width: '200px',
        height: '48px',
        fontSize: `${custom.font.md}`,
      },
    }),
    lg: () => ({
      field: {
        width: '100%',
        height: '56px',
        fontSize: `${custom.font.md}`,
      },
      addon: {
        height: '56px',
      },
    }),
  },
  variants: {
    primary: (props: InputProps) => ({
      field: {
        color: `${colors.brand.gray900}`,
        borderRadius: '8px',
        ...(props.isInvalid ? { borderRight: 'none' } : {}),
      },
      addon: {
        borderRadius: '8px',
        bg: `${colors.brand.white}`,
      },
    }),
    profilePrimary: (props: InputProps) => ({
      field: {
        color: `${colors.brand.gray900}`,
        borderRadius: '8px',
        border: `1px solid ${colors.brand.gray200}`,
        bg: `${colors.brand.white}`,
        _disabled: {
          bg: `${colors.brand.gray25}`,
        },
      },
    }),
    profileSecondary: (props: InputProps) => ({
      field: {
        color: `${colors.brand.gray900}`,
        borderRadius: '8px',
        border: `1px solid ${colors.brand.gray200}`,
        bg: `${colors.brand.gray200}`,
        _disabled: {
          bg: `${colors.brand.gray200}`,
        },
      },
      addon: {
        bg: `${colors.brand.gray200}`,
      },
    }),
    secondary: (props: InputProps) => ({
      field: {
        borderRadius: '8px',
        ...(props.isInvalid ? { borderRight: 'none' } : {}),
        ...focus,
        bg: `${colors.brand.white}`,
      },
    }),
    phone: () => ({
      field: {
        padding: '0px',
        borderRadius: '8px',
        ...focus,
        border: `1px solid ${colors.brand.gray200}`,
        borderLeft: 'none',
      },
      addon: {
        padding: '0px',
        margin: '0px',
        borderRadius: '8px',
        bg: `${colors.brand.white}`,
        border: `1px solid ${colors.brand.gray200}`,
        borderRight: 'none',
      },
    }),
    date: () => ({
      field: {
        borderRadius: '8px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: `${custom.font.md}`,
        ...focus,
      },
    }),
    password: (props: InputProps) => ({
      field: {
        bg: `${colors.brand.white}`,
        borderRadius: '8px',
        ...focus,
        ...(props.isDisabled && {
          border: `1px solid ${colors.brand.gray200}`,
        }),

        _disabled: {
          bg: `${colors.brand.gray25}`,
          border: `1px solid ${colors.brand.gray200}`,
        },
      },
      addon: {},
    }),
    coupon: () => ({
      field: {
        width: '345px',
        border: `2px solid ${colors.brand.gray200}`,
        borderRadius: '8px',

        _disabled: {
          border: `1px solid ${colors.brand.gray200} !important`,
        },

        _focus: {
          border: `1px solid ${colors.brand.gray200}`,
          boxShadow: `0 0 0px 1px ${colors.brand.gray200}}`,
        },
      },
    }),
    pinInput: () => ({
      pinInputField: {
        border: `2px solid ${colors.brand.gray200}`,
        borderRadius: '8px',
      },
    }),
    cardDate: () => ({
      field: {
        height: '100%',
        alignSelf: 'center',
        borderRadius: '8px',
        textTransform: 'uppercase',
        fontSize: `${custom.font.md}`,
        ...focus,
      },
    }),
    otherSpecialNeeds: () => ({
      field: {
        border: 0,
        bg: 'none',
        borderRadius: 0,
        borderBottom: `2px solid ${colors.brand.gray500}`,
        margin: 0,
        height: '24px',
        position: 'relative',
        paddingBottom: '8px',
        _disabled: {
          borderBottom: `2px solid ${colors.brand.gray200}`,
        },
      },
    }),
    username: () => ({
      field: {
        padding: `0 ${custom.space.lg}`,
        _disabled: {
          backgroundColor: `${colors.brand.white}`,
          opacity: 1,
          color: `${colors.brand.gray500}`,
        },
      },
    }),
    searchFriends: () => ({
      field: {
        height: '40px',
        border: `1px solid transparent`,
        _focus: {
          border: `1px solid ${colors.brand.gray200}`,
          boxShadow: `0 0 0px 1px ${colors.brand.gray200}}`,
        },
      },
    }),
    searchBook: () => ({
      field: {
        height: '40px',
        maxWidth: '360px',
      },
    }),
    searchDeck: () => ({
      field: {
        height: '40px',
      },
    }),
    referral: () => ({
      field: {
        border: `1px solid transparent`,
        _disabled: { bg: 'white', opacity: 1 },
        _focus: {
          border: `1px solid ${colors.brand.gray200}`,
          boxShadow: `0 0 0px 1px ${colors.brand.gray200}}`,
        },
      },
    }),
    teacherStudyGroupsAddStudents: ({
      borderColor = 'transparent',
    }: {
      borderColor?: string;
    }) => ({
      field: {
        border: `1px solid ${
          colors.brand[borderColor as keyof typeof colors.brand] || borderColor
        }`,
        borderRadius: '8px',
        height: '40px',
        width: '100%',
        _focus: {
          border: `1px solid ${colors.brand.gray200}`,
          boxShadow: `0 0 0px 1px ${colors.brand.gray200}}`,
        },
      },
    }),
    deepWriterPopover: () => ({
      field: {
        height: '40px',
        border: `1px solid ${colors.brand.gray300}`,
        width: '100%',
        borderRadius: '8px',
        fontSize: `${custom.font.sm}`,
      },
    }),
    remark: () => ({
      field: {
        color: `${colors.brand.gray900}`,
        height: '40px',
        borderRadius: '8px',
        border: `1px solid ${colors.brand.gray200}`,
        bg: `${colors.brand.white}`,
        _disabled: {
          bg: `${colors.brand.gray25}`,
        },
      },
    }),
    search: () => ({
      field: {
        border: `1px solid ${colors.brand.gray200}`,
        borderRadius: '8px',
        height: '40px',
        width: '100%',
        fontSize: '16px',
        bg: `white`,
        color: `${colors.brand.gray900}`,
        _focus: {
          border: `1px solid ${colors.brand.gray200}`,
          boxShadow: 'none',
        },
        _placeholder: {
          color: `${colors.brand.gray500}`,
        },
      },
    }),
    bottomSearch: () => ({
      field: {
        border: `none`,
        borderRadius: '32px',
        height: '64px',
        width: '100%',
        fontSize: '16px',
        bg: `${colors.brand.blue10}`,
        color: `${colors.brand.gray900}`,
        _focus: {
          border: `none`,
          boxShadow: 'none',
        },
        _placeholder: {
          color: `${colors.brand.gray500}`,
        },
      },
    }),
  },

  defaultProps: {
    size: 'lg',
    variant: 'primary',
  },
};
