/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useCompleteFTUEStepMutation } from '@/store/onboarding/mutations';
import { FTUEStepDto } from '@/store/user-profile/types';
import { getActiveFtueStep } from '@/components/teacher/FTUE/constants';
import { fetchExams } from '@/store/exams/queries';
import {
  commonUnregisteredLearnerRoutes,
  commonUnregisteredParentRoutes,
  commonUnregisteredTeacherRoutes,
  routes,
} from '@/components/common/ProtectedRoute/routes';
import { useUserProfileContext } from './UserProfileContext';
import { useGlobalContext } from './GlobalContext';
import { getRoleGroup } from './AuthContext/helpers';
import { useAuthContext } from './AuthContext';

type FTUEContextType = {
  FTUEsteps: FTUEStepDto[];
  activeFTUEStep: number;
  onCompleteStep: (step: number) => void;
};

export const FTUEContext = createContext<FTUEContextType>({
  FTUEsteps: [],
  activeFTUEStep: 0,
  onCompleteStep: () => {},
});

const FTUEProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { state: user, dispatch } = useUserProfileContext();
  const groupRole = getRoleGroup(user.type);
  const [isSteps, setIsSteps] = useState(false);
  const [activeFTUEStep, setActiveFTUEStep] = useState(0);
  const FTUEsteps = useMemo(() => user.steps, [user.steps]);
  const { data: examsConfigQuery } = fetchExams(
    groupRole === 'learner' && isSteps
  );

  const firstSubjectCode = examsConfigQuery
    ?.find((e) => e.code === user?.exams?.[0]?.code)
    ?.subjects?.sort((a, b) => a?.name?.localeCompare(b?.name))?.[0]?.code;

  const publicRoutes = routes.public;

  const isPublic =
    publicRoutes.has(router.pathname) ||
    commonUnregisteredParentRoutes.has(router.pathname) ||
    commonUnregisteredLearnerRoutes.has(router.pathname) ||
    commonUnregisteredTeacherRoutes.has(router.pathname);

  const { mutate: completeFTUEStep } = useCompleteFTUEStepMutation((data) => {
    dispatch({
      type: 'UPDATE_FTUE_STEPS',
      payload: data?.steps,
    });

    const aFTUEStep = getActiveFtueStep(data?.steps);
    setActiveFTUEStep(aFTUEStep || 0);
  });

  const onCompleteStep = async (step: number) => {
    completeFTUEStep(step);
  };

  const contextValue = useMemo(
    () => ({
      FTUEsteps,
      activeFTUEStep,
      onCompleteStep,
    }),
    [FTUEsteps, activeFTUEStep]
  );

  return (
    <FTUEContext.Provider value={contextValue}>{children}</FTUEContext.Provider>
  );
};

export const useFTUEContext = () => useContext(FTUEContext);

export { FTUEProvider };
