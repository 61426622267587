import { custom } from '@/styles/custom';

type FontSize = 'xs' | 'sm' | 'md' | 'lg';

const colorSchemes = {
  'brand.orange': {
    color: 'brand.primary700',
    borderColor: 'brand.primary700',
    checked: {
      bg: 'brand.primary25',
    },
  },
  'brand.blue': {
    color: 'brand.blue800',
    borderColor: 'brand.blue800',
    checked: {
      bg: 'brand.blue25',
    },
  },
  'brand.turquoise': {
    color: 'brand.turquoise900',
    borderColor: 'brand.turquoise900',
    checked: {
      bg: 'brand.turquoise25',
    },
  },
  'brand.success': {
    color: 'brand.success700',
    borderColor: 'brand.success700',
    checked: {
      bg: 'brand.success25',
    },
  },
  'brand.pink': {
    color: 'brand.pink800',
    borderColor: 'brand.pink800',
    checked: {
      bg: 'brand.pink50',
    },
  },
  'brand.darkRed': {
    color: '#D43229',
    borderColor: '#D43229',
    checked: {
      bg: '#F9C3C0',
    },
  },
  'brand.lightRed': {
    color: '#EE4B42',
    borderColor: '#EE4B42',
    checked: {
      bg: '#FDEBEA',
    },
  },
  'brand.darkYellow': {
    color: '#967D04',
    borderColor: '#967D04',
    checked: {
      bg: '#FFFAE6',
    },
  },
  'brand.lightGreen': {
    color: 'brand.success500',
    borderColor: 'brand.success500',
    checked: {
      bg: 'brand.success25',
    },
  },
  'brand.darkGreen': {
    color: 'brand.success700',
    borderColor: 'brand.success700',
    checked: {
      bg: 'brand.success50',
    },
  },
};

export const CheckBoxStyles = {
  sizes: {
    sm: {
      icon: { fontSize: '2xs' },
      label: {
        fontSize: `${custom.font.sm}`,
      },
    },
    md: () => ({
      icon: { fontSize: '2xs' },
      label: {
        fontSize: `${custom.font.md}`,
      },
    }),
    lg: () => ({
      icon: { fontSize: '2xs' },
      label: {
        fontSize: `${custom.font.lg}`,
      },
    }),
  },
  baseStyle: () => ({
    control: {
      width: '20px',
      height: '20px',
      border: '1px solid',
      borderRadius: '6px',
      borderColor: 'brand.gray500',
      bg: 'white',

      _checked: {
        _disabled: {
          color: 'brand.gray400',
          borderColor: 'brand.gray400',
          bg: 'transparent',
        },
      },
      _disabled: {
        borderColor: 'brand.gray400',
        bg: 'transparent',
      },
    },

    label: {
      _disabled: {
        opacity: 1,
        color: 'brand.gray400',
      },
      color: 'brand.gray900',
      fontWeight: 700,
    },
  }),
  variants: {
    primary: ({
      fontSize,
      colorScheme,
    }: {
      fontSize: FontSize;
      colorScheme?: keyof typeof colorSchemes;
    }) => {
      const isSchemeStyle = colorScheme && colorSchemes[colorScheme]?.color;

      return {
        control: {
          bg: 'brand.white',

          _checked: {
            bg: isSchemeStyle
              ? colorSchemes[colorScheme]?.checked?.bg
              : 'brand.primary25',
            color: isSchemeStyle
              ? colorSchemes[colorScheme]?.color
              : 'brand.primary700',
            borderColor: isSchemeStyle
              ? colorSchemes[colorScheme]?.borderColor
              : 'brand.primary700',

            _hover: {
              bg: isSchemeStyle
                ? colorSchemes[colorScheme]?.checked?.bg
                : 'brand.primary25',
              borderColor: isSchemeStyle
                ? colorSchemes[colorScheme]?.borderColor
                : 'brand.primary700',
            },
          },
          _focus: {
            boxShadow: 'none',
          },
        },
        label: {
          color: isSchemeStyle
            ? colorSchemes[colorScheme]?.color
            : 'brand.gray900',
          ...{
            ...(!!fontSize && {
              fontSize: `${custom.font[fontSize]}`,
            }),
          },
        },
      };
    },

    secondary: () => ({
      label: {
        fontSize: `${custom.font.sm}`,
      },
      control: {
        borderColor: 'brand.orange',

        _checked: {
          bg: '#FDF0E6',
          color: 'brand.orange',
        },
      },
    }),
    teacherNotificationsFilter: (props: any) => ({
      control: {
        bg: 'brand.white',

        _checked: {
          bg: 'brand.primary25',
          color: 'brand.primary700',
          borderColor: 'brand.primary700',

          _hover: {
            bg: 'brand.primary25',
            borderColor: 'brand.primary700',
          },
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      container: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
      },
      label: {
        padding: '4px',
        fontWeight: props?.fontColor ? '900' : '700',
        color: props.fontColor,
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
    dropdownCheckboxItem: (props: any) => ({
      control: {
        bg: 'brand.white',
        _checked: {
          bg: 'brand.primary25',
          color: 'brand.primary500',
          borderColor: 'brand.primary500',
          _hover: {
            bg: 'brand.primary25',
            borderColor: 'brand.primary500',
          },
        },
        _hover: {
          bg: 'brand.primary25',
          borderColor: 'brand.primary500',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
      container: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
      },
      label: {
        padding: '0',
        fontWeight: props?.fontColor ? '900' : '700',
        color: props.fontColor,
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        fontSize: '14px',
        lineHeight: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        marginInlineStart: '0px',
        _hover: {
          bg: 'brand.primary25',
          borderColor: 'brand.primary500',

          '& .chakra-checkbox__control': {
            bg: 'brand.primary25',
            color: 'brand.primary500',
            borderColor: 'brand.primary500',
          },
        },
      },
    }),
    base: ({ size }: { size: FontSize }) => {
      return {
        container: {
          _hover: {
            // Apply hover styles to the control when hovering the whole container
            '& > .chakra-checkbox__control': {
              bg: 'brand.purple50',
              borderColor: 'brand.purple800',
            },
            '& > .chakra-checkbox__control[aria-checked=true], .chakra-checkbox__control[data-checked]':
              {
                bg: 'brand.purple800',
                borderColor: 'brand.purple800',
              },
          },
        },
        control: {
          bg: 'brand.white',
          borderColor: 'brand.gray500',
          pointerEvents: 'auto',
          _checked: {
            bg: 'brand.purple900',
            color: 'brand.white',
            borderColor: 'brand.purple900',

            _hover: {
              bg: 'brand.purple800',
              borderColor: 'brand.purple800',
            },

            _disabled: {
              borderColor: 'brand.gray400',
              color: 'brand.gray400',
              bg: 'transparent',
            },
          },
          _focus: {
            boxShadow: 'none',
          },
          _disabled: {
            borderColor: 'brand.gray400',
            bg: 'transparent',
          },
          _hover: {
            bg: 'brand.purple50',
            borderColor: 'brand.purple800',
          },
        },
        label: {
          color: 'brand.gray900',
          ...(size && {
            fontSize: `${custom.font[size]}`,
          }),
          pointerEvents: 'auto',
          _disabled: {
            color: 'brand.gray400',
          },
        },
      };
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
};
