import { UserRoleDto } from '@/contexts/AuthContext/types';

type routesObjectType = {
  public: Set<string>;
  roles: Record<
    UserRoleDto,
    {
      registered: Set<string>;
      unregistered: Set<string>;
    }
  >;
};

const commonRegisteredTeacherRoutes = new Set<string>([
  '/teacher',
  '/teacher/account',
  '/teacher/profile-settings',
  '/teacher/dashboard',
  '/teacher/exam-syllabi',
  '/teacher/resources',
  '/teacher/study-tools/morphology',
  '/teacher/study-tools/geogebra',
  '/teacher/morphology-maker',
  '/teacher/morphology-review',
  '/teacher/staff-details',
  '/teacher/study-groups',
  '/teacher/assignments',
  '/teacher/assignments/create/[step]',
  '/teacher/reading-hall/[hall]',
  '/teacher/book-preview',
  '/teacher/deep-reading',
  '/teacher/articles',
]);

export const commonUnregisteredTeacherRoutes = new Set<string>([
  '/teacher/registration/step2',
  '/teacher/registration/step3',
]);

const commonRegisteredLearnerRoutes = new Set<string>([
  '/learner',
  '/learner/assessment-hall',
  '/learner/progress-report',
  '/learner/assessment-hall/[exam]',
  // '/learner/assessment-hall/past-papers',
  // '/learner/assessment-hall/past-papers/[exam]',
  '/learner/exams-preparation',
  '/learner/exams-preparation-result',
  '/learner/morphology-maker',
  '/learner/morphology-review',
  '/learner/exam-review',
  '/learner/deep-reading',
  '/learner/reading-hall/[hall]',
  '/learner/study-tools/morphology',
  // '/learner/study-tools/flash-cards',
  '/learner/study-tools/flash-cards/edit',
  '/learner/study-tools/flash-cards/library',
  '/learner/study-tools/flash-cards/game',
  '/learner/study-tools/word-maps',
  // '/learner/study-group',
  '/learner/settings',
  '/learner/no-exams',
  '/learner/deep-writer',
  '/learner/deep-writer/check',
  '/learner/deep-writer/exam',
  '/learner/deep-writer/exam-review',
  '/learner/writing-hall/proofreader',
  '/learner/geogebra',
  '/learner/my-exams',
  '/learner/flashcards',
  // '/learner/friends',
  '/learner/friends/[hall]',
  '/learner/profile-settings',
]);

export const commonUnregisteredLearnerRoutes = new Set<string>([
  '/learner/registration/step1',
  '/learner/registration/step2',
  '/learner/registration/step3',
]);

const commonRegisteredStudentRoutes = new Set<string>([
  '/student',
  '/student/school',
  '/student/exams-preparation',
  '/student/exams-preparation-result',
  '/student/exam-review',
  '/student/profile-settings',
  '/student/study-tools/morphology',
  '/student/morphology-maker',
  '/student/morphology-review',
  '/student/deep-reading',
  '/student/deep-writer',
  '/student/deep-writer/check',
  '/student/deep-writer/exam',
  '/student/deep-writer/exam-review',
  '/student/study-tools/geogebra',
  '/student/study-tools/flash-cards',
  '/student/study-tools/flash-cards/edit',
  '/student/study-tools/flash-cards/library',
  '/student/study-tools/flash-cards/game',
  '/student/reading-hall/[hall]',
  '/student/writing-hall/proofreader',
  '/student/book-preview',
  '/student/geogebra',
]);

const commonUnregisteredStudentRoutes = new Set<string>([
  '/student/registration/step1',
  '/student/registration/step2',
  '/student/registration/step3',
]);

const commonRegisteredParentRoutes = new Set<string>([
  '/parent',
  '/parent/registration/payment-confirmation',
  '/parent/account',
  // '/parent/resources',
  '/parent/exam-syllabi',
  '/parent/add-student',
  '/parent/all-resources',
  '/parent/study-hall',
  '/parent/reading-hall/[hall]',
  '/parent/learner/profile-settings',
  '/parent/learner-details',
  '/parent/learner-details/[learnerId]',
  '/parent/learner-details/[learnerId]/exam',
  '/parent/learner-details/[learnerId]/exam/[exam]',
  '/parent/learner-details/[learnerId]/exam/no-exams',
  '/parent/learner',
  '/parent/learner/[learnerId]',
  '/parent/learner/[learnerId]/overall',
  // '/parent/learner/[learnerId]/exam',
  // '/parent/learner/[learnerId]/exam/[exam]',
  '/parent/learner/[learnerId]/exam/no-exams',
]);

export const commonUnregisteredParentRoutes = new Set<string>([
  '/parent/registration/step2',
  '/parent/registration/step3',
  '/parent/registration/step4',
]);

export const routes: routesObjectType = {
  public: new Set<string>([
    '/',
    '/login',
    '/redirect',
    '/signup',
    '/payment-not-found',
    '/reset-password',
    '/forgot-password',
    '/auth/google/redirect',
    '/auth/microsoft/redirect',
    '/email-sent-confirmation',
    '/create-new-password',
    '/logout-with-error',
    '/parent/registration',
    '/parent/registration/step1',
    '/learner/registration/code-verification',
    '/student/registration/code-verification',
    '/teacher/registration',
    '/teacher/registration/step1',
    '/school/register/select-role',
    '/school-admin/register/form',
    '/school-admin/stuff-details',
    '/school-admin/register/success',
  ]),
  roles: {
    'home-payer': {
      registered: new Set<string>([...commonRegisteredParentRoutes]),
      unregistered: new Set<string>([...commonUnregisteredParentRoutes]),
    },
    'home-learner': {
      registered: new Set<string>([...commonRegisteredLearnerRoutes]),
      unregistered: new Set<string>([...commonUnregisteredLearnerRoutes]),
    },
    'home-teacher': {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    'school-teacher': {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    'school-student': {
      registered: new Set<string>([...commonRegisteredStudentRoutes]),
      unregistered: new Set<string>([...commonUnregisteredStudentRoutes]),
    },
    'school-admin': {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    'school-admin-plus': {
      registered: new Set<string>([
        ...commonRegisteredTeacherRoutes,
        // Add any additional routes specific to school-admin-plus
      ]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    'school-head-dept': {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    'school-head-year': {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    tutor: {
      registered: new Set<string>([]),
      unregistered: new Set<string>([]),
    },
    staff: {
      registered: new Set<string>([]),
      unregistered: new Set<string>([]),
    },
    'head-of-department': {
      registered: new Set<string>([]),
      unregistered: new Set<string>([]),
    },
    'head-of-year': {
      registered: new Set<string>([]),
      unregistered: new Set<string>([]),
    },
    'super-admin': {
      registered: new Set<string>([]),
      unregistered: new Set<string>([]),
    },
    learner: {
      registered: new Set<string>([...commonRegisteredLearnerRoutes]),
      unregistered: new Set<string>([...commonUnregisteredLearnerRoutes]),
    },
    customer: {
      registered: new Set<string>([...commonRegisteredParentRoutes]),
      unregistered: new Set<string>([...commonUnregisteredParentRoutes]),
    },
    teacher: {
      registered: new Set<string>([...commonRegisteredTeacherRoutes]),
      unregistered: new Set<string>([...commonUnregisteredTeacherRoutes]),
    },
    student: {
      registered: new Set<string>([...commonRegisteredStudentRoutes]),
      unregistered: new Set<string>([...commonUnregisteredLearnerRoutes]),
    },
  },
};
